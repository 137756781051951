import { AppFCC } from '@types';
import { AccessControlLink, LinkContext } from '@managers/links';
import { TextBold } from '@kit/text/text-bold';
import { HeaderMenuItemIconStyled, HeaderMenuItemLinkContentStyled, HeaderMenuItemStyled, HeaderMenuItemTextStyled } from './styled';
import { HEADER_MENU_ITEM_ICON_POSITION, HeaderMenuItemProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const HeaderMenuItem: AppFCC<HeaderMenuItemProps> = props => {
  const {
    className,
    icon,
    iconPosition,
    text,
    ...restProps
  } = props;
  const isRightIcon = iconPosition === HEADER_MENU_ITEM_ICON_POSITION.RIGHT;
  return _jsx(HeaderMenuItemStyled, {
    className: className,
    children: _jsx(AccessControlLink //
    , {
      className: "group",
      ...restProps,
      children: _jsx(LinkContext.Consumer, {
        children: ({
          isActive
        }) => {
          return _jsxs(HeaderMenuItemLinkContentStyled, {
            isActive: isActive,
            children: [icon && !isRightIcon ? _jsx(HeaderMenuItemIconStyled, {
              children: icon
            }) : null, _jsx(HeaderMenuItemTextStyled, {
              children: _jsx(TextBold, {
                isHovered: isActive,
                children: text
              })
            }), icon && isRightIcon ? _jsx(HeaderMenuItemIconStyled, {
              children: icon
            }) : null]
          });
        }
      })
    })
  });
};