import { useMemo } from 'react';
import { ROUTES } from '@routing/routes';
import { AppFCC } from '@types';
import { HeaderMenuItem } from '@kit/header/header-menu/header-menu-item';
import { HeaderMenuCallSetupWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const HeaderMenuCallSetupWidget: AppFCC<HeaderMenuCallSetupWidgetProps> = props => {
  const {
    onClick
  } = props;
  const routeDescriptor = useMemo(() => ROUTES.callSetup.index.getDescriptor(), []);
  const accessControlCallbacks = useMemo(() => [], []);
  return _jsx(HeaderMenuItem, {
    text: "\u0417\u0432\u043E\u043D\u043A\u0438",
    routeDescriptor: routeDescriptor,
    checkActive: true,
    accessControlCallbacks: accessControlCallbacks,
    onClick: onClick
  });
};