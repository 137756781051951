import { useMemo } from 'react';
import { isSpecialist } from '@helpers/member';
import { ROUTES } from '@routing/routes';
import { AppFCC } from '@types';
import { currentMemberGetProfileIsFilledAndApproved, currentMemberGetRoleType } from '@selectors/current-member';
import { useAccessControlCallbackByEmailConfirmation } from '@hooks/access-control/access-control-by-email-confirmation';
import { useAccessControlCallbackByFilledProfile } from '@hooks/access-control/access-control-by-filled-profile';
import { useTypedSelector } from '@hooks/store';
import { HeaderMenuItem } from '@kit/header/header-menu/header-menu-item';
import { HeaderMenuTop100WidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const HeaderMenuTop100Widget: AppFCC<HeaderMenuTop100WidgetProps> = props => {
  const {
    onClick
  } = props;
  const roleType = useTypedSelector(currentMemberGetRoleType);
  const isFilledAndApproved = useTypedSelector(currentMemberGetProfileIsFilledAndApproved);
  const routeDescriptor = useMemo(() => ROUTES.top100.index.getDescriptor(), []);
  const accessControlCallbackByEmailConfirmation = useAccessControlCallbackByEmailConfirmation(routeDescriptor);
  const accessControlCallbackByFilledProfile = useAccessControlCallbackByFilledProfile(routeDescriptor);
  const accessControlCallbacks = useMemo(() => isFilledAndApproved ? [accessControlCallbackByFilledProfile] : [accessControlCallbackByEmailConfirmation, accessControlCallbackByFilledProfile], [accessControlCallbackByEmailConfirmation, accessControlCallbackByFilledProfile, isFilledAndApproved]);
  if (isSpecialist(roleType)) {
    return null;
  }
  return _jsx(HeaderMenuItem, {
    text: "\u041B\u0443\u0447\u0448\u0438\u0435 100",
    routeDescriptor: routeDescriptor,
    checkActive: true,
    accessControlCallbacks: accessControlCallbacks,
    onClick: onClick
  });
};