import { useMemo } from 'react';
import { isDesktop } from '@helpers/geometry';
import { ROUTES } from '@routing/routes';
import { AppFCC } from '@types';
import { geometryGetType } from '@selectors/geometry';
import { useTypedSelector } from '@hooks/store';
import { HEADER_MENU_ITEM_ICON_POSITION, HeaderMenuItem } from '@kit/header/header-menu/header-menu-item';
import { HeaderMenuSearchWidgetBoldIconStyled, HeaderMenuSearchWidgetIconStyled } from './styled';
import { HeaderMenuSearchWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const HeaderMenuSearchWidget: AppFCC<HeaderMenuSearchWidgetProps> = props => {
  const {
    onClick
  } = props;
  const geometryType = useTypedSelector(geometryGetType);
  const _isDesktop = isDesktop(geometryType);
  const routeDescriptor = useMemo(() => ROUTES.search.index.getDescriptor(), []);
  const accessControlCallbacks = useMemo(() => [], []);
  return _jsx(HeaderMenuItem, {
    icon: _isDesktop ? _jsx(HeaderMenuSearchWidgetBoldIconStyled, {}) : _jsx(HeaderMenuSearchWidgetIconStyled, {}),
    iconPosition: _isDesktop //
    ? HEADER_MENU_ITEM_ICON_POSITION.LEFT : HEADER_MENU_ITEM_ICON_POSITION.RIGHT,
    text: "\u041F\u043E\u0438\u0441\u043A",
    routeDescriptor: routeDescriptor,
    checkActive: true,
    accessControlCallbacks: accessControlCallbacks,
    onClick: onClick
  });
};