import { useMemo } from 'react';
import { ROUTES } from '@routing/routes';
import { AppFCC } from '@types';
import { HeaderMenuItem } from '@kit/header/header-menu/header-menu-item';
import { HeaderMenuAboutWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const HeaderMenuAboutWidget: AppFCC<HeaderMenuAboutWidgetProps> = props => {
  const {
    onClick
  } = props;
  const routeDescriptor = useMemo(() => ROUTES.about.index.getDescriptor(), []);
  const accessControlCallbacks = useMemo(() => [], []);
  return _jsx(HeaderMenuItem, {
    text: "\u041E \u043D\u0430\u0441",
    routeDescriptor: routeDescriptor,
    checkActive: true,
    accessControlCallbacks: accessControlCallbacks,
    onClick: onClick
  });
};