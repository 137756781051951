import { AppFCC } from '@types';
import { HeaderMenuItemsStyled, HeaderMenuStyled } from './styled';
import { HeaderMenuProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const HeaderMenu: AppFCC<HeaderMenuProps> = props => {
  const {
    className,
    children
  } = props;
  return _jsx(HeaderMenuStyled, {
    className: className,
    children: _jsx(HeaderMenuItemsStyled, {
      children: children
    })
  });
};