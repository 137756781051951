import { AccessControlLinkProps } from '@managers/links/access-control-link';
import { LinkProps } from '@managers/links/link';
export enum HEADER_MENU_ITEM_ICON_POSITION {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}
export type HeaderMenuItemProps = {
  icon?: React.ReactElement;
  iconPosition?: HEADER_MENU_ITEM_ICON_POSITION;
  text: string;
} & AccessControlLinkProps;
export type HeaderMenuItemOnClick = LinkProps['onClick'];
export type HeaderMenuItemLinkContentStyledProps = {
  isActive?: boolean;
};