import { AppFCC } from '@types';
import { HeaderMenuWidgetProps } from '@widgets/header/header-menu/types';
import { HeaderMenu } from '@kit/header/header-menu';
import { HeaderMenuAboutWidget } from './header-menu-about';
import { HeaderMenuCallSetupWidget } from './header-menu-call-setup';
import { HeaderMenuSearchWidget } from './header-menu-search/header-menu-search';
import { HeaderMenuTop100Widget } from './header-menu-top100';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const HeaderMenuWidget: AppFCC<HeaderMenuWidgetProps> = props => {
  const {
    linkOnClick
  } = props;
  return _jsxs(HeaderMenu, {
    children: [_jsx(HeaderMenuSearchWidget, {
      onClick: linkOnClick
    }), _jsx(HeaderMenuTop100Widget, {
      onClick: linkOnClick
    }), _jsx(HeaderMenuAboutWidget, {
      onClick: linkOnClick
    }), _jsx(HeaderMenuCallSetupWidget, {
      onClick: linkOnClick
    })]
  });
};